<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="sign-up">
          <h1 class="header">
            Zmień hasło
          </h1>
          <form @submit.prevent="onSubmit">
            <p v-if="isError" class="sign-up__error">
              Podane dane są nieprawidłowe
            </p>
            <p>
              <form-input
                v-model="$v.email.$model"
                label="e-mail"
                name="email"
                error-message="Podaj adres e-mail"
                :has-error="$v.email.$error && hasEmailBlurred"
                @blur="onEmailBlur"
              />
            </p>
            <p v-if="isSuccess">
              Link do resetowania hasła został wysłany na {{ emailSentTo }}
            </p>
            <p class="sign-up__submit">
              <button
                class="button"
                type="submit"
                :disabled="!isFormValid"
                :class="{
                  'button--loading': isLoading,
                }"
              >
                Zmień hasło
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import FormInput from '@/vue/components/user-attributes-form/form-input.vue';

export default {
  name: 'PasswordResetRequest',

  components: { FormInput },

  mixins: [validationMixin],

  validations: {
    email: {
      required,
      email,
    },
  },

  data: () => ({
    hasEmailBlurred: false,
    emailSentTo: '',
    email: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
  }),

  computed: {
    isFormValid() {
      return !this.$v.$invalid;
    },
  },

  methods: {
    ...mapActions('user', ['forgotPassword']),

    onEmailBlur() {
      this.hasEmailBlurred = true;
    },

    onPasswordBlur() {
      this.hasPasswordBlurred = true;
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        this.isError = false;
        await this.forgotPassword({ email: this.email });
        this.resetForm();
      } catch (e) {
        this.isError = true;
        console.log('Unable reset password', e);
      } finally {
        this.isLoading = false;
      }
    },

    resetForm() {
      this.isSuccess = true;
      this.hasEmailBlurred = false;
      this.emailSentTo = this.email;
      this.email = '';
      this.$v.$reset();
    },
  },
};
</script>
